var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer__row"},[_c('div',{staticClass:"footer__row__column"},[_vm._m(0),_c('div',{staticClass:"footer__row__column__links"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.sort,staticClass:"footer__row__column__links__link"},[_c('router-link',{class:{routeDisabled: _vm.routeName === category.link},attrs:{"to":`/kategorie/${category.link}`}},[_vm._v(_vm._s(category.name))])],1)}),0)]),_c('div',{staticClass:"footer__row__column"},[_vm._m(1),_c('div',{staticClass:"footer__row__column__links"},[_c('div',{staticClass:"footer__row__column__links__link"},[_c('router-link',{attrs:{"to":`/regulamin`}},[_vm._v("Regulamin")])],1),_c('div',{staticClass:"footer__row__column__links__link"},[_c('router-link',{attrs:{"to":`/politykaprywatnosci`}},[_vm._v("Polityka Prywatności")])],1),_c('div',{staticClass:"footer__row__column__links__link"},[_c('router-link',{attrs:{"to":`/koszyk`}},[_vm._v("Koszyk")])],1),_c('div',{staticClass:"footer__row__column__links__link"},[_c('router-link',{attrs:{"to":`/kontakt`}},[_vm._v("Kontakt")])],1),_c('div',{staticClass:"footer__row__column__links__link"},[_c('router-link',{attrs:{"to":`/reklamacje`}},[_vm._v("Reklamacje i zwroty")])],1),_vm._m(2),_vm._m(3)])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__row__column__name"},[_c('span',[_vm._v("Kategorie")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__row__column__name"},[_c('span',[_vm._v("Informacje")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__row__column__links__link"},[_c('a',{attrs:{"href":"https://mojapaczka.dpd.com.pl/login","target":"_blank"}},[_vm._v("Śledzenie Przesyłek")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__row__column__links__link"},[_c('a',{attrs:{"href":"https://blog.kompre.pl/","target":"_blank"}},[_vm._v("Blog")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__row__column"},[_c('div',{staticClass:"footer__row__column__name"},[_c('span',[_vm._v("Kontakt")])]),_c('div',{staticClass:"footer__row__column__contact"},[_c('div',{staticClass:"footer__row__column__contact__section"},[_c('div',{staticClass:"footer__row__column__contact__section__icon"},[_c('i',{staticClass:"fi-rr-paper-plane icon"})]),_c('div',{staticClass:"footer__row__column__contact__section__info"},[_c('span',{staticClass:"title"},[_vm._v("KOMPRE Sp. z o. o.")]),_c('span',[_vm._v("Rogaszyce, ul. Wesoła 37")]),_c('span',[_vm._v("63-500 Ostrzeszów")]),_c('span',[_vm._v("Otwarte: 9.00 - 16.00")])])]),_c('div',{staticClass:"footer__row__column__contact__section border"},[_c('div',{staticClass:"footer__row__column__contact__section__icon"},[_c('i',{staticClass:"fi-rr-smartphone icon"})]),_c('div',{staticClass:"footer__row__column__contact__section__info"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("Dział Sprzedaży")]),_c('span',[_c('a',{staticClass:"hoverable",attrs:{"href":"tel:+48510188531"}},[_vm._v("+48 510 188 531")])])]),_c('div',[_c('span',{staticClass:"title"},[_vm._v("Wsparcie Techniczne")]),_c('span',[_c('a',{staticClass:"hoverable",attrs:{"href":"tel:+48661801461"}},[_vm._v("+48 661 801 461")])])])])]),_c('div',{staticClass:"footer__row__column__contact__section border"},[_c('div',{staticClass:"footer__row__column__contact__section__icon"},[_c('i',{staticClass:"fi-rr-envelope icon"})]),_c('div',{staticClass:"footer__row__column__contact__section__info"},[_c('div',[_c('span',{staticClass:"title"},[_vm._v("Dział Sprzedaży")]),_c('span',[_c('a',{staticClass:"hoverable",attrs:{"href":"mailto:sklep@kompre.pl"}},[_vm._v("sklep@kompre.pl")])])]),_c('div',[_c('span',{staticClass:"title"},[_vm._v("Reklamacje")]),_c('span',[_c('a',{staticClass:"hoverable",attrs:{"href":"mailto:serwis@kompre.pl"}},[_vm._v("serwis@kompre.pl")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__skwebhouse"},[_c('a',{attrs:{"href":"https://complaia.systems/","target":"_blank"}},[_c('span',[_vm._v(" Made with "),_c('i',{staticClass:"fi-rr-heart webhouse"}),_vm._v(" by Complaia Systems 2022. ")])])])
}]

export { render, staticRenderFns }